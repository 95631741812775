<template>
  <en-drawer :model-value="modelValue" :title="`${form.data.id ? '编辑' : '新建'}估价单`" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')">
      <en-form-item label="服务顾问" prop="advisor.id">
        <select-maintain
          v-model="form.data.advisor"
          :ajax="{
            action: 'GET /enocloud/common/advisorteam/advisor',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'user.name', value: 'user' }"
          value-key="id"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="维修类别" prop="serviceCategory.id">
        <select-maintain
          v-model="form.data.serviceCategory"
          :ajax="{
            action: 'GET /enocloud/common/service/category',
            params: (params) => (params.payload = { pagingEnabled: false })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="本次里程">
        <en-input v-model="form.data.currentMileage"></en-input>
      </en-form-item>
      <en-form-item label="客户来源">
        <select-maintain
          v-model="form.data.salesmanType"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['CLKTYPE'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
          @change="form.salesmanType.change"
        ></select-maintain>
      </en-form-item>
      <en-form-item v-if="form.data.salesmanType?.code && form.data.salesmanType?.code !== 'N'" label="介绍人" prop="salesman">
        <template v-if="['C', 'U'].includes(form.data.salesmanType?.code)">
          <select-maintain
            v-model="form.data.salesman"
            :ref="setRef('formSalesManRef')"
            :ajax="{
              action: 'GET /enocloud/common/clerk',
              params: (params, value) => (params.payload = { code: form.data.salesmanType?.code, quickSearch: value })
            }"
            :props="{ label: 'name', value: '' }"
            value-key="id"
            remote
            class="w-full"
          ></select-maintain>
        </template>
        <template v-if="['O', 'I'].includes(form.data.salesmanType?.code)">
          <select-maintain
            :model-value="form.data.salesman?.name"
            :ajax="{
              action: 'GET /enocloud/common/hint/:hintTypeStr',
              params: (params) => (params.paths = [form.data.salesmanType?.code === 'O' ? 'SALSMAN' : 'CUSISNSSMAN'])
            }"
            :props="{ label: 'name', value: 'name' }"
            allow-create
            @change="form.salesman.change"
            class="w-full"
          ></select-maintain>
        </template>
      </en-form-item>
      <en-form-item label="发票类型">
        <select-maintain
          v-model="form.data.invoiceTypeName"
          :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['INVCTP']) }"
          :props="{ label: 'name', value: 'name' }"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="预计完工">
        <en-date-picker
          v-model="form.data.expectedCompletionDatetime"
          type="datetime"
          value-format="YYYY-MM-DDThh:mm:ss"
          class="w-full"
        ></en-date-picker>
      </en-form-item>
      <en-form-item label="是否保养">
        <select-maintain
          v-model="form.data.maintenanceFlag"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="下保里程">
        <en-input v-model="form.data.nextMaintenanceMileage" :disabled="Boolean(!form.data.vehicle?.id)"></en-input>
      </en-form-item>
      <en-form-item label="下保时间">
        <en-date-picker v-model="form.data.nextMaintenanceDate" class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="回收旧件">
        <select-maintain
          v-model="form.data.recycleFlag"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="本次存油">
        <select-maintain
          v-model="form.data.remainingOil"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['RMGOIL']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.comment"></en-input>
      </en-form-item>
      <en-form-item label="故障描述">
        <select-maintain
          v-model="form.data.description"
          :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['SERVDESC']) }"
          :props="{ label: 'name', value: 'name' }"
          allow-create
          placeholder="请选择故障描述"
          multiple
          collapseTags
          collapseTagsPanel
          default-first-option
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="故障原因">
        <select-maintain
          v-model="form.data.solution"
          :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['SERVSOLU']) }"
          :props="{ label: 'name', value: 'name' }"
          allow-create
          default-first-option
          placeholder="请选择故障原因"
          class="w-full"
        ></select-maintain>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudServiceDefinitions['ServiceQuotationDto']>,
    vehicle: Object as PropType<Record<string, any> | undefined>,
    customer: Object as PropType<Record<string, any> | undefined>,
    vehicleServiceGroup: Object as PropType<EnocloudServiceDefinitions['VehicleServiceGroupDto'] | undefined>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (value: number | undefined) => ['number', 'undefinded'].includes(typeof value)
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.data?.id) {
          this.form.init()
          this.form.data.id = this.data.id
          this.form.get()
        } else if (this.vehicle?.id && this.customer?.id) {
          this.form.init()
          this.form.data.vehicle = { ...this.vehicle } as EnocloudServiceDefinitions['VehicleDto']
          this.form.data.customer = { ...this.customer } as EnocloudServiceDefinitions['CustomerDto']
          this.form.data.vehicleServiceGroup = this.vehicleServiceGroup
        }
      }
    }
  },

  config: {
    children: {
      form: {
        rules: {
          'advisor.id': [{ required: true, message: '请选择服务顾问' }],
          'serviceCategory.id': [{ required: true, message: '请选择维修类别' }],
          inspectDate: [{ required: true, message: '请选择检测日期' }]
        },
        ajax: {
          get: {
            action: 'GET /enocloud/service/quotation/:serviceQuotationId',
            data: 'object',
            init: true,
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/service/quotation',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/service/quotation',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        children: {
          salesmanType: {
            change() {
              if (this.form.data.salesmanType?.code === 'C' || this.form.data.salesmanType?.code === 'U') {
                this.refs.formSalesManRef?.ajax()
              }
            }
          },
          salesman: {
            change(value: string) {
              if (typeof value === 'string') {
                this.form.data.salesman = { name: value }
              }
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            const res = await this.form[this.form.data.id ? 'update' : 'submit']()
            this.footer.cancel.click()
            this.emit('confirm', (res.data[0] as number | undefined) || this.form.data.id)
          }
        }
      }
    }
  }
})
</script>
